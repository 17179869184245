import { Link } from "react-router-dom";

import disconnected_link from
  "../../../assets/vector-images/panel/disconnected-link.svg";
import { InfoPanel } from "../../../components/layout";
import { rootPaths } from "../../../routes/paths";



function ExpiredInvite() {
  return (
    <InfoPanel
      icon={disconnected_link}
      title="Invitation Link Expired"
      description={(
        <>
          <div>
            This invitation link has expired.
          </div>
          <div>
            Please a new one from the one{" "}
            who shared this with you.
          </div>
        </>
      )}
    >
      <Link
        to={rootPaths.HOMEPAGE}
        className="button white-black"
      >
        Back Home
      </Link>
    </InfoPanel>
  );
}

export default ExpiredInvite;