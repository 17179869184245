import { Link, useOutletContext } from "react-router-dom";
import { getName } from "country-list";
import { ReactNode } from "react";
import moment from "moment";

import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import { firstTwoWords } from "../../../utils/strings";
import { ProfileSetupContext } from "..";
import Styles from "./styles";



type UserData = {
  title: string;
  content: ReactNode;
}


function ProfileSummary() {
  const { user, address } = useOutletContext<ProfileSetupContext>();

  const userNameData: UserData[] = [
    {
      title: "First Name",
      content: user.firstName
    },
    {
      title: "Middle Name",
      content: user.middleName
    },
    {
      title: "Last Name",
      content: user?.lastName
    },
  ]
  const userNickName: UserData[] = [
    {
      title: "Nickname / Display Name",
      content: user?.nickName ? user.nickName : "-"
    }
  ]
  const userDob: UserData[] = [
    {
      title: "Date of Birth",
      content: user?.dob ? moment(user.dob).format("Do MMMM, YYYY") : "-"
    }
  ]
  const userAddressData: UserData[] = [
    {
      title: "Address Summary",
      content: address?.addressLine1 ? address.addressLine1 : "-"
    },
    {
      title: "House Number",
      content: address?.houseNumber ? address.houseNumber : "-"
    },
    {
      title: "House Name",
      content: address?.houseName ? address.houseName : "-"
    },
    {
      title: "Flat",
      content: address?.flat ? address.flat : "-"
    },
    {
      title: "Street",
      content: address?.street ? address.street : "-"
    },
    {
      title: "City",
      content: address?.city ? address.city : "-"
    },
    {
      title: "Postcode",
      content: address?.postCode ? address.postCode : "-"
    },
    {
      title: "County",
      content: address?.county ? address.county : "-"
    },
    {
      title: "Country",
      content: (address?.country
        ? firstTwoWords(getName(address.country))
        : "-"
      )
    },
    {
      title: "Phone Number",
      content: (`
        ${address?.countryCode ? `+${address.countryCode}` : ""}
        ${address?.phoneNumber ? parseInt(`${address.phoneNumber}`) : ""}
        `
      )
    }
  ]



  return (
    <Styles className="page-wrapper">
      {/* Heading */}
      <header>
        <h3 className="title">
          Confirm your Details
        </h3>
        <div className="subtitle">
          The following details will be used to confirm your identity.
          <br />
          Please ensure your Name, Date of Birth, and Address<br />
          match those on your government-issued ID.
        </div>
      </header>

      <section>
        {/* Name Data */}
        <div className="user-data">
          <h6>Name</h6>
          <ul className="card">
            {userNameData.map((data, index) =>
              <li key={`${data.title}-${index}`}
                className="flex justify-between"
              >
                <div className="title">
                  {data.title}
                </div>
                <div className="content">
                  {data.content}
                </div>
              </li>
            )}
          </ul>
          <Link
            to={profileSetupPaths.NAME_ENTRY}
            className="button white-primary auto-width mlr-auto sm"
          >
            Edit Name
          </Link>
        </div>

        {/* Nickname */}
        <div className="user-data">
          <h6>Nickname</h6>
          <ul className="card">
            {userNickName.map((data, index) =>
              <li key={`${data.title}-${index}`}
                className="flex justify-between"
              >
                <div className="title">
                  {data.title}
                </div>
                <div className="content">
                  {data.content}
                </div>
              </li>
            )}
          </ul>
          <Link
            to={profileSetupPaths.NICKNAME_ENTRY}
            className="button white-primary auto-width mlr-auto sm"
          >
            Edit Nickname
          </Link>
        </div>

        {/* DOB */}
        <div className="user-data">
          <h6>Date of Birth (DOB)</h6>
          <ul className="card">
            {userDob.map((data, index) =>
              <li key={`${data.title}-${index}`}
                className="flex justify-between"
              >
                <div className="title">
                  {data.title}
                </div>
                <div className="content">
                  {data.content}
                </div>
              </li>
            )}
          </ul>
          <Link
            to={profileSetupPaths.DOB_ENTRY}
            className="button white-primary auto-width mlr-auto sm"
          >
            Edit DOB
          </Link>
        </div>

        {/* Address */}
        <div className="user-data">
          <h6>Address</h6>
          <ul className="card">
            {userAddressData.map((data, index) =>
              <li key={`${data.title}-${index}`}
                className="flex justify-between"
              >
                <div className="title">
                  {data.title}
                </div>
                <div className="content">
                  {data.content}
                </div>
              </li>
            )}
          </ul>
          <Link
            to={profileSetupPaths.ADDRESS_ENTRY}
            className="button white-primary auto-width mlr-auto sm"
          >
            Edit Address
          </Link>
        </div>

        <Link
          to={profileSetupPaths.KYC_CHECK}
          className="button"
        >
          Looks Good, Proceed.
        </Link>
      </section>
    </Styles>
  );
}

export default ProfileSummary;