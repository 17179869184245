import { Link } from "react-router-dom";

import { profileSetupPaths } from "../../../../routes/profileSetup/paths";
import id_error from
  "../../../../assets/vector-images/panel/id-card-error.svg";
import { InfoPanel } from "../../../../components/layout";



function KycVerificationFailed() {
  return (
    <div className="page-wrapper">
      <InfoPanel
        icon={id_error}
        title="Verification Failed"
        description={(
          `Unfortunately, we couldn't validate your identity.${" "}
          Please upload your documents for manual validation.`
        )}
      >
        <Link
          to={profileSetupPaths.KYC_UPLOAD_DOCUMENT}
          className="button white-black"
        >
          UPLOAD DOCUMENT
        </Link>
      </InfoPanel>
    </div>
  );
}

export default KycVerificationFailed;