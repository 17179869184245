import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";

import { ActiveSubscription } from "../../../../requests/subscriptions/types";
import useSubscriptions from "../../../../requests/subscriptions";
import { consolePaths } from "../../../../routes/_console/paths";
import { currencySymbol } from "../../../../utils/strings";
import { decimalPrice } from "../../../../utils/numbers";
import { daysToMonths } from "../../../../utils/dates";
import { Loader } from "../../../../components/layout";
import {
  hasActiveSubscription
} from "../../../../requests/subscriptions/utils";
import { ConsoleContext } from "../..";
import Styles from "./styles";



function SubscriptionDetail() {
  const { user } = useOutletContext<ConsoleContext>();
  const navigate = useNavigate();
  const { getMySubscription } = useSubscriptions();
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | null
  >(null);


  useEffect(() => {
    getMySubscription()
      .then((subscription) => {
        const activeSubscription = hasActiveSubscription(subscription);
        if (activeSubscription) {
          setActiveSubscription(activeSubscription);
        } else {
          navigate(consolePaths.SUBSCRIPTION_OPTIONS);
        }
      }).catch(() => {
        navigate(consolePaths.SUBSCRIPTION_OPTIONS);
      });
  }, [])



  return (
    <>
      <h1 className="page-title">
        Subscription
      </h1>
      <Styles className="page-wrapper">
        {activeSubscription ? (
          <>
            <header>
              <div className="flex">
                <h1 className="title">
                  Subscription
                </h1>
              </div>
            </header>

            {/* Active Plan */}
            <div className="subscription-plan">
              <section className="plan-summary">
                {/* Plan Name */}
                <div className="head flex align-center">
                  <div className="name">
                    {activeSubscription.planPrice.plan.name} Plan
                  </div>
                  <span>-</span>
                  <div className="price flex bold">
                    <div className="value">
                      {currencySymbol(activeSubscription.planPrice.currency)}
                      {decimalPrice(activeSubscription.planPrice.amount)}
                    </div>
                    <span>/</span>
                    <div className="interval">
                      {activeSubscription.planPrice.interval}
                    </div>
                  </div>
                </div>
                {/* Plan Discount */}
                {(
                  activeSubscription.discount &&
                  !activeSubscription.discount.expired
                ) ? (
                  <div className="discount flex">
                    <div className="title">
                      Active Discount
                    </div>
                    <span>-</span>
                    <div className="details grey-text">
                      {(activeSubscription.discount.
                        planPriceDiscount.percentage)}% off for {""
                      }{daysToMonths(activeSubscription.discount.
                        planPriceDiscount.daysDuration
                      )}
                    </div>
                  </div>
                ) : null}
              </section>
              <div className="content">
                <div className="title">
                  {activeSubscription.planPrice.plan.featureTitle}
                </div>
                <ul className="features flex column">
                  {activeSubscription.planPrice.plan.planFeatures.map((feature) =>
                    <li className="feature" key={feature.id}>
                      {feature.description}
                    </li>
                  )}
                </ul>
              </div>

              {/* Upgrade Plan - Family Admin */}
              {user.familyRole === "admin" ? (
                <Link
                  to={consolePaths.SUBSCRIPTION_OPTIONS}
                  className="link underline change-plan"
                >
                  Change Subscription Plan
                </Link>
              ) : null}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </Styles>
    </>
  );
}

export default SubscriptionDetail;